<template>
	<div id="headerbanner">
		
		<img src="~/assets/images/aboutus/aboutHeader.png" alt="找不到资源">
		<div class="nowask">
			立即咨询
		</div>
		<keep-alive>
			<ul class="headertab">
				<li v-for="(item,index) in headertabList" :key="index" 
				:class="index == activeIndex ? 'active':'noactive'"
				@click="toActivePage(index)">
					<span>
						{{item.title}}
					</span>
				</li>
			</ul>
		</keep-alive>
	</div>
</template>

<script>
export default {
	name:'HeaderBanner',
	data(){
		return {
			headertabList:[
				{id:0,title:"企业简介",path:'/companyIntro'},
				{id:1,title:"应用简介",path:'/appIntro'},
				{id:2,title:"招贤纳士",path:'/recruiting'},
				{id:3,title:"联系我们",path:'/contactUs'},
			],
		}
	},
	computed:{
		activeIndex(){
			if(this.$store.state.aboutstore.aboutus_index == null){
				return 0
			}else{
				return this.$store.state.aboutstore.aboutus_index
			}
		}
	},
	created(){
		let auindex = this.activeIndex
		if(auindex != null || auindex != "" || auindex != undefined){
			this.$store.dispatch('aboutstore/aboutus_index',auindex)
			this.$router.push(this.headertabList[auindex].path)
		}else{
			this.$store.dispatch('aboutstore/aboutus_index',0)
			this.$router.push(this.headertabList[0].path)

		}

	},

	methods:{
		toActivePage(index){
			this.$router.push(this.headertabList[index].path)
			this.$store.dispatch('aboutstore/aboutus_index',index)
			// window.sessionStorage.setItem('aboutus_index',index)
		}
	}
	
}
</script>

<style lang="scss" scoped>
	@media(min-width: 1201px){
		@import '@/components/content/aboutus/HeaderBanner.scss';
	}
	@media(max-width: 1200px){
		@import '@/components/content/aboutus/HeaderBanner_phone.scss';
	}

</style>
