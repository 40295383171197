<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:关于我们页,用于展示
	History: 暂无
-->
<template>
	<div id="aboutus">
		<div class="aboutus_in"> 
			<header-banner></header-banner>
			<router-view></router-view>	
			<footer-page></footer-page>
		</div>
	</div>
</template>


<script>
import navTab from "@/components/common/nav/navTab.vue";
import HeaderBanner from '@/components/content/aboutus/HeaderBanner.vue'
import footerPage from '@/components/common/footer/footerPage.vue'
export default {
	name: 'Aboutus',
	data(){
		return{
			
		}
	},
	created() {
		
		this.$store.commit('updateIsActiveIndex',4)
		
		
	},
	components:{
		HeaderBanner,
		footerPage,
	}
}
</script>

<style lang="scss" scoped>
	@media(min-width: 1201px){
		#aboutus{
			width: 100%;
			display: flex;
			justify-content: center;	
			.aboutus_in{
				width: 1920px;
			}
		}
	}
</style>
